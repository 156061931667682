<template>
    <div>
        <div class="kt-heading kt-heading--md">Data Pasien</div>
        <div class="kt-list">
            <div class="kt-list__item">
                <span class="kt-list__text col-md-3 text-muted">{{ $t('common.name') }}</span>
                <span
                    class="kt-list__text col-md-7"
                    v-text="patient.patientName"
                ></span>
            </div>
            <div class="kt-list__item">
                <span class="kt-list__text col-md-3 text-muted">{{ $t('common.dob') }}</span>
                <span
                    class="kt-list__text col-md-7"
                    v-if="patient.dateOfBirth"
                >{{patient.dateOfBirth | formatDate}}</span>
            </div>
            <div class="kt-list__item" v-if="patient.gender">
                <span class="kt-list__text col-md-3 text-muted">{{ $t('common.gender') }}</span>
                <span
                    class="kt-list__text col-md-7"
                    v-text="$t(patient.gender)"
                ></span>
            </div>
            <div class="kt-list__item">
                <span class="kt-list__text col-md-3 text-muted">{{ $t('common.phoneNo') }}</span>
                <span
                    class="kt-list__text col-md-7"
                >
                {{patient.mobilePhone1 | formatTelepon}}
                </span>
            </div>
            <div class="kt-list__item">
                <span class="kt-list__text col-md-3 text-muted">{{ $t('common.email') }}</span>
                <span
                    class="kt-list__text col-md-7"
                    v-text="patient.email"
                ></span>
            </div>
            <div class="kt-list__item">
                <span class="kt-list__text col-md-3 text-muted">No. Rekam Medik</span>
                <span
                    class="kt-list__text col-md-7"
                    v-text="patient.medicalNo"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            patient: {
                type: Object
            },
        },
        filters: {
            formatTelepon: function(data) {
               return helpers.formatPhoneNumber(data);
            },
            formatDate: function(data) {
                return moment(data).format('DD MMM YYYY')
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
