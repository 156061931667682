import { render, staticRenderFns } from "./PatientSearch.vue?vue&type=template&id=557e3070&scoped=true"
import script from "./PatientSearch.vue?vue&type=script&lang=js"
export * from "./PatientSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557e3070",
  null
  
)

export default component.exports