<template>
    <div>
        <div class="row">
            <div class="col-12 text-right">
                <button type="button" class="btn btn-default btn-elevate btn-circle btn-icon mr-2"  @click="add"
                        v-if="showSearch">
                    <i class="fas fa-plus"></i>
                </button>
                <button type="button" class="btn btn-default btn-elevate btn-circle btn-icon" v-if="!showSearch" @click="search">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
        <template v-if="showSearch">
            <patient-search v-model="patient" @select="closeSearch"></patient-search>
        </template>
       <template v-else>
           <template v-if="patient.id || patient.patientNames">
               <detail-patient :patient="patient" ></detail-patient>
           </template>
           <template v-else>
               <form-patient v-model="patient" ref="form" @success="go" :is-edit="isEdit"></form-patient>
           </template>
       </template>
       <template v-if="!showSearch">
        <hr/>
        <div class="row mt-5">
            <div class="col-12">
                <div class="text-right">
                    <button type="button" class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper" @click="submit()">
                        {{ $t("button.nextStep") }}
                    </button>
                </div>
            </div>
        </div>
        </template>
    </div>
</template>

<script>
    import PatientSearch from "./../../../_select/PatientSearch";
    import DetailPatient from "./DetailPatient";
    import FormPatient from "./FormPatient";
    import Pasien from "./../../../../model/pasien-model";

    export default {
        components: {
            PatientSearch,
            DetailPatient,
            FormPatient
        },
        data() {
            return {
                showSearch: true,
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
           value: [Object],
           isEdit:{type: Boolean, default: false}
        },
        computed: {
            patient: {
                get () {
                    return this.value
                },
                set (newValue) {
                    this.$emit('change', newValue);
                }
            },
        },
        methods: {
            add(){
                this.showSearch = false;
                this.patient = new Pasien();
            },
            search(){
                this.showSearch = true;
                this.patient = new Pasien();
            },
            submit(){
                if(this.patient != null){
                    if(this.patient.id || (this.patient.patientNames)){
                        this.go();
                    } else {
                        this.$refs.form.submit();
                    }
                }
            },
            closeSearch(){
                this.showSearch = false;
            },
            go(){
                this.$emit('go',2);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
