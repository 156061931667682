<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Appointment Doctor</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="close()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="kt-portlet__body kt-portlet__body--fit">
            <div
              class="
                kt-grid kt-grid--desktop-xl kt-grid--ver-desktop-xl
                kt-wizard-v1
              "
              id="kt_wizard_v1"
              data-ktwizard-state="step-first"
            >
              <div class="kt-grid__item kt-wizard-v1__aside">
                <div class="kt-wizard-v1__nav">
                  <div class="kt-wizard-v1__nav-items">
                    <div
                      class="kt-wizard-v1__nav-item"
                      href="#"
                      data-ktwizard-type="step"
                      data-ktwizard-state="current"
                    >
                      <span>1</span>
                    </div>
                    <div
                      class="kt-wizard-v1__nav-item"
                      href="#"
                      data-ktwizard-type="step"
                    >
                      <span>2</span>
                    </div>
                    <div
                      class="kt-wizard-v1__nav-item"
                      href="#"
                      data-ktwizard-type="step"
                    >
                      <span>3</span>
                    </div>
                  </div>
                  <div class="kt-wizard-v1__nav-details">
                    <div
                      class="kt-wizard-v1__nav-item-wrapper"
                      data-ktwizard-type="step-info"
                      data-ktwizard-state="current"
                    >
                      <div class="kt-wizard-v1__nav-item-title">
                        Data Pasien
                      </div>
                      <div class="kt-wizard-v1__nav-item-desc">
                        Data Pasien yang akan dibuatkan reservasi. Jika ada
                        perubahan data pasien, dapat diubah langsung melalui
                        <a href="/pasien">Halaman Pasien.</a>
                      </div>
                    </div>
                    <div
                      class="kt-wizard-v1__nav-item-wrapper"
                      data-ktwizard-type="step-info"
                    >
                      <div class="kt-wizard-v1__nav-item-title">Reservasi</div>
                      <div class="kt-wizard-v1__nav-item-desc">
                        Buat Reservasi dengan jadwal dokter yang sesuai.
                      </div>
                    </div>
                    <div
                      class="kt-wizard-v1__nav-item-wrapper"
                      data-ktwizard-type="step-info"
                    >
                      <div class="kt-wizard-v1__nav-item-title">Tambahan</div>
                      <div class="kt-wizard-v1__nav-item-desc">
                        Pilih penanggung jawab pembayaran (wajib) dan pesan
                        (opsional).
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper"
              >
                <div class="kt-form" id="kt_form">
                  <div
                    class="kt-wizard-v1__content border-bottom-0"
                    data-ktwizard-type="step-content"
                    data-ktwizard-state="current"
                  >
                    <wizard-content-one
                      ref="wizone"
                      @go="goTo"
                      v-model="reservasi.patient"
                      :is-edit="reservasi.generatedId ? true: false"
                    ></wizard-content-one>
                  </div>
                  <div
                    class="kt-wizard-v1__content border-bottom-0"
                    data-ktwizard-type="step-content"
                  >
                    <wizard-content-two
                      ref="wiztwo"
                      @go="goTo"
                      v-model="reservasi"
                      :tipe="tipe"
                    ></wizard-content-two>
                  </div>
                  <div
                    class="kt-wizard-v1__content border-bottom-0"
                    data-ktwizard-type="step-content"
                  >
                    <wizard-content-three
                      @go="goTo"
                      v-model="reservasi"
                      @save="save"
                    ></wizard-content-three>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="close" >{{ $t('button.close') }}</button>	
                    <button type="button" class="btn btn-default btn-bold btn-upper btn-font-md">{{ $t('button.save') }}</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WizardContentOne from "./_components/WizardContentOne.vue";
import WizardContentTwo from "./_components/WizardContentTwo.vue";
import WizardContentThree from "./_components/WizardContentThree.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import Reservasi from "./../../../model/reservasi-model";
import Pasien from "./../../../model/pasien-model";
import SlotJadwalType from "./../../../constants/slot-jadwal-type-enum";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");

import SweetAlertTemplate from "./../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./../../_js/BlockUI";
import mapperData from '../../../services/mapperData';
const blockUI = new BlockUI();
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();

export default {
  components: {
    WizardContentOne,
    WizardContentTwo,
    WizardContentThree,
  },
  props: {
    modalName: { type: String, default: "addAppointmentModal" },
    callerUrl: { type: String, default: "doc-appo" },
  },
  data() {
    return {
      wizard: null,
      reservasi: new Reservasi(),
      tipe: SlotJadwalType.EMPTY,
      isSPV: false
      // tempReservasi: {
      //     tanggalKunjungan: '',
      //     jadwalId: '',
      //     dokterId: ''
      // }
    };
  },
  computed: {
    ...mapState("DayCalendarStore", {
      event: (state) => state.selectedEvent,
      fromEvent: (state) => state.fromEvent,
      showModalTempWa: (state) => state.showModalTempWa,
      detailPanel: (state) => state.detailPanel
    }),
    ...mapState("DoctorAppointmentStore", {
            selectedPanel: state => state.selectedPanel,
        }),
  },
  methods: {
    ...mapMutations({
      setShowModalReservasi: "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
      setShowModalHelp: "DoctorAppointmentStore/SET_SHOW_MODAL_HELP",
      setShowModalHistory: "DayCalendarStore/SET_SHOW_MODAL_HISTORY",
      setShowModalTempWa: "DayCalendarStore/SET_SHOW_MODAL_TEMPWA",
    }),
    ...mapActions({
      reloadCalendar: "DoctorAppointmentStore/RELOAD_CALENDAR",
      SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG",
    }),
    close() {
      $("#" + this.modalName).modal("hide");
      this.setShowModalReservasi(false);
    },
    goTo(page) {
      this.wizard.goTo(page);
    },
    async onInit() {
      if (this.callerUrl != "doc-appo") {
        const { data } = await ReservationRepository.getReservationAppointmentBackoffice(
            this.selectedPanel.generatedId
          );
          this.reservasi = mapperData.mapperDataShowReservasi(data);
          // this.reservasi = {...this.event.reservasi};
          this.$refs.wizone.showSearch = false;
      } else {
        if (this.fromEvent) {
          if (this.event.reservasi == null) {
            const reservasi = new Reservasi();
            const dataEmptySlot = mapperData.mapperDataShowReservasi(this.event.data)
            this.reservasi.patient = new Pasien();
            this.reservasi = {...reservasi , ...dataEmptySlot}
            this.reservasi.tanggalKunjungan = moment(this.event.start).format(
              "YYYY-MM-DD"
            );
            // this.reservasi.jadwal = this.event.jadwal;
            this.reservasi.isNewReserved = true
            this.reservasi.jamMulai = moment(this.event.start).format("HH:mm");
            this.reservasi.jamSelesai = moment(this.event.end).format("HH:mm");
          } else {
            const { data } = await ReservationRepository.getReservationAppointmentBackoffice(
              this.detailPanel.generatedId
            );
            this.reservasi = mapperData.mapperDataShowReservasi(data);
            // this.tempReservasi.tanggalKunjungan = data.tanggalKunjungan
            // this.tempReservasi.dokterId = data.dokter.id
            // this.tempReservasi.jadwalId = data.jadwal.id
            // this.reservasi = {...this.event.reservasi};
            this.$refs.wizone.showSearch = false;
          }
          this.tipe = this.event.type;
        } else {
          this.reservasi = new Reservasi();
          this.reservasi.patient = new Pasien();
          this.reservasi.tanggalKunjungan = moment().format("YYYY-MM-DD");
        }
      }
      setTimeout(() => {
        this.$refs.wiztwo.getDataDokter();
        if (this.reservasi.jadwal != null) {
          this.$refs.wiztwo.getListReservasi([
            this.reservasi.jadwal.dokter.id,
          ]);
        }
      }, 10);
    },
    checkReservationChange(tempReservasi, {date, idJadwal, idDokter}) {
        if(tempReservasi.tanggalKunjungan) {
            if(tempReservasi.tanggalKunjungan != date || 
                tempReservasi.dokterId != idDokter ||
                tempReservasi.jadwalId != idJadwal
            ) {
                return true
            }
        }
        return false
    },
    save(opsiPayment) {
      var vx = this;
      let backup = {
        dateOfBirth: vx.reservasi.tanggalLahir,
        mobilePhone1: helpers.formatPhoneNumberNotZero(vx.reservasi.telp),
        patientName: vx.reservasi.nama,
        jenisKelamin: vx.reservasi.gender,
      };
      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Data Reservasi Baru akan ditambahkan.",
        swalType: "info",
        onConfirmButton: function () {
          vx.reservasi.tanggalLahir = vx.reservasi.patient.dateOfBirth;
          vx.reservasi.namaPasien = vx.reservasi.patient.patientName;
          vx.reservasi.telp = helpers.formatPhoneNumberNotZero(
            vx.reservasi.patient.mobilePhone1
          );
          vx.reservasi.gender = mapperData.isCheckObjectGender(vx.reservasi.patient)
          vx.reservasi.nama = vx.reservasi.patient.patientName;
          // vx.reservasi.gender = vx.reservasi.patient.jenisKelamin;
          vx.reservasi.patient.mobilePhone1 = helpers.formatPhoneNumberNotZero(
            vx.reservasi.patient.mobilePhone1
          );
          if (vx.reservasi.smsNotif) {
            vx.reservasi.smsNotif = helpers.formatPhoneNumberNotZero(
              vx.reservasi.smsNotif
            );
          }
        // console.log(vx.tempReservasi)
        // console.log(vx.reservasi)
        // const nowReservation = {
        //     date: vx.reservasi.tanggalKunjungan,
        //     idJadwal: vx.reservasi.jadwal.id,
        //     idDokter: vx.reservasi.jadwal.dokter.id

        // }
        
        // vx.teleconsult = true
        // vx.overrideTarif = 0
            blockUI.blockModal();
            
        // const checkReservation = vx.checkReservationChange(vx.tempReservasi, nowReservation)
        // const checkReservation = false
        // vx.reservasi.createdVia = "BACKOFFICE";
        if(vx.reservasi.generatedId) {
            vx.update();
        } else {
          vx.create();
        }
        //   if (vx.reservasi.id == null) {
        //     vx.reservasi.createdVia = "BACKOFFICE";
        //     blockUI.blockModal();
        //     vx.create();
        //   } else if (vx.reservasi.cancelStatus ) {
        //     vx.reservasi.gender = backup.jenisKelamin;
        //     vx.reservasi.queue = null;
        //     vx.reservasi.queueId = null;
        //     blockUI.blockModal();
        //     vx.rescheduleCancelledBooking();
        //   } else if(checkReservation) {
        //     //   cancel && reschedule 
        //     vx.reservasi.gender = backup.jenisKelamin;
        //     blockUI.blockModal();
        //     vx.rescheduleCancelledBooking();
        //     // vx.cancelReservation(vx.reservasi.id, backup)
        //   } else {
        //     vx.reservasi.tanggalLahir = backup.dateOfBirth;
        //     vx.reservasi.namaPasien = backup.patientName;
        //     vx.reservasi.telp = backup.mobilePhone1;
        //     vx.reservasi.nama = backup.patientName;
        //     vx.reservasi.gender = backup.jenisKelamin;
        //     blockUI.blockModal();
        //     vx.update();
        //   }
        },
        onCancelButton: function () {
          blockUI.unblockModal();
        },
      });
    },
    async create() {
      var vx = this;
      vx.reservasi.patient.id = parseInt(vx.reservasi.patient.id)
      if( vx.reservasi.teleconsult && vx.isSPV) vx.reservasi.overrideTarif = vx.$refs.wiztwo.price
      const dataReservasi = mapperData.postSendAppointment(vx.reservasi)
      const reservation = vx.isSPV
        ? ReservationRepository.createOverride(dataReservasi)
        : ReservationRepository.create(dataReservasi);
      await reservation
        .then((response) => {
          // vx.reloadCalendar()
          // vx.SOCK_MSG('doctor-appointment')
          if (vx.reservasi.teleconsult) {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Reservasi Telekonsul sedang di proses.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.reloadCalendar();
                vx.close();
              },
            });
          } else {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Reservasi baru berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.reloadCalendar();
                vx.close();
              },
            });
          }
        })
        .catch((error) => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Reservasi baru tidak dapat dibuat.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            },
          });
        });
    },

    async update() {
      var vx = this;
      vx.reservasi.patient.id = parseInt(vx.reservasi.patient.id)
      if( vx.reservasi.teleconsult && vx.isSPV) vx.reservasi.overrideTarif = vx.$refs.wiztwo.price
      const dataReservasi = mapperData.postSendAppointmentReschedule(vx.reservasi)
      const reservationUpdate = vx.isSPV
        ? ReservationRepository.postRescheduleOverride(dataReservasi)
        : ReservationRepository.postRescheduleAppointment(dataReservasi);
      await reservationUpdate
        .then((response) => {
          vx.reloadCalendar();
          vx.close();
          let instance = new SweetAlert().showInfo({
            swalTitle: "Berhasil!",
            swalText: "Reservasi berhasil diubah.",
            swalType: "success",
            onConfirmButton: function () {
              blockUI.unblockModal();
            },
          });
        })
        .catch((error) => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Reservasi tidak dapat diubah.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            },
          });
        });
    },
    async rescheduleCancelledBooking() {
      var vx = this;
      let reservasiId = vx.reservasi.id;
      vx.reservasi.id = null;
       const reservationCanceLReschedule = vx.reservasi.isOverride
        ? ReservationRepository.reservasiRescheduleWithCancelOVerride(reservasiId, vx.reservasi)
        : ReservationRepository.reservasiRescheduleWithCancel(reservasiId, vx.reservasi)
      await reservationCanceLReschedule
        .then((response) => {
          vx.close();
          let instance = new SweetAlert().showInfo({
            swalTitle: "Berhasil!",
            swalText: "Reservasi berhasil diubah.",
            swalType: "success",
            onConfirmButton: function () {
              vx.reloadCalendar();
              blockUI.unblockModal();
              vx.setShowModalHistory(true);
            },
          });
        })
        .catch((error) => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Reservasi tidak dapat diubah.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            },
          });
        });
    },
    async cancelReservation(id, backup) {
        const payload = [
            {
                reservasiId: id
            }
        ]
        ReservationRepository.cancelReservations(payload)
            .then(() => {
                this.reservasi.gender = backup.jenisKelamin;
                this.reservasi.queue = null;
                this.reservasi.queueId = null;
                
            })
            .catch(err => {
                toastr.error('Reservasi Gagal di Reschedule');
                blockUI.unblockModal();
            })  
    },
    checkPermission: function (method) {
        return checkPermission.isCanAccess(method);
    },
  },

  mounted() {
    $("#" + this.modalName).modal();
    this.wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1,
    });
    // let vx = this;
    this.onInit();
    this.isSPV =
        this.checkPermission(
            "ReservasiResource.PUT.Reservasi.BackOffice.OverrideTarif"
        ) &&
        this.checkPermission(
            "ReservasiResource.POST.Reservasi.BackOffice.OverrideTarif"
        );
  },
};
</script>